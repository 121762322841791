import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { useExperiments } from '@alltrails/experiments';
import InstallInterstitial from './InstallInterstitial';

type Props = {
  promptLocation: InstallPromptLocation;
  pageId?: number;
  isOpen?: boolean;
  onRequestClose?: () => void;
  displayTrigger?: CarouselDisplayTrigger;
  isScrollLimitActive?: boolean;
};

// Design link: https://www.figma.com/file/oevB4bXh8KLLDe1Yp8rBW2?type=design%27&node-id=11925:74124

export default function InstallInterstitialWrapper({ promptLocation, pageId, isOpen, displayTrigger, onRequestClose, isScrollLimitActive }: Props) {
  const experiments = useExperiments();
  const variant = experiments['web-growth-rm-app-install-on-auth-wall']?.value;

  if (variant === 'treatment_a' || variant === 'treatment_b' || variant === 'treatment_c' || variant === 'treatment_d') {
    return null;
  }

  return (
    <InstallInterstitial
      promptLocation={promptLocation}
      pageId={pageId}
      isOpen={isOpen}
      displayTrigger={displayTrigger}
      onRequestClose={onRequestClose}
      isScrollLimitActive={isScrollLimitActive}
    />
  );
}
